<template>
  <div>Endosso alteração</div>
</template>
<script>
export default {
  name: "alteracao-informacoes-do-endosso",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss"></style>
